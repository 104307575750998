$(function () {
    jQuery('img.svg').each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');
    });
});

$(function () {
    $("#rental-product-gallery .carousel-indicators li").click(function () {
        $("#rental-product-gallery").carousel($(this).data('slide-to'));
    });
});

$(function () {
    $(window).load(function () {
        equalheight('#stores-listing li');
        equalheight('#frontpage-stores li');
    });

    $(window).resize(function () {
        equalheight('#stores-listing li');
        equalheight('#frontpage-stores li');
    });
});

jQuery(document).ready(gformInitDatepicker);

function gformInitDatepicker()
{
    jQuery('.datepicker').each(function(key, val)
    {
        console.log(val);
    });
    //    this.datepicker({
    //        minDate: +2,
    //        maxDate: +548
    //    })
    //);
}

$(function() {
    var el = $(".gfield_date_dropdown_year").children().get();
    for (i=0; i<el.length; i++) {
        var ops = $(el[i]).children().get();

        if (ops.length > 0 && $(ops).first().val() < $(ops).last().val()) {
            $(el[i]).empty();
            $(el[i]).html(ops.reverse());
        }
    }
});

$(document.body).delegate('#input_1_12_3', 'mousedown', function() {
    (function(sel) {
        var el = $(sel);
        var ops = $(el).children().get();
        if ( ops.length > 0 && $(ops).first().val() < $(ops).last().val() ) {
            $(el).empty();
            $(el).html(ops.reverse());
        }
    })(this);
});